const redirectUrls = [
	{ from: '/services/', to: '/uslugi' },
	{ from: '/uslugi/', to: '/uslugi' },
	{ from: '/reviews/', to: '/reviews' },
	{ from: '/news/', to: '/articles' },
	{ from: '/contacts/', to: '/contacts' },
	{ from: '/about-us/', to: '/about-us' },
	{ from: '/story/', to: '/about-us' },
	{ from: '/manager/', to: '/about-us' },
	{ from: '/info/', to: '/about-us' },
	{ from: '/employees/', to: '/employees' },
	{ from: '/employees_line/', to: '/employees' },
	{
		from: '/vnzh-grazhdanstvo-rf-i-mobilizaciya/',
		to: '/articles/vnzh-grazhdanstvo-rf-i-mobilizaciya',
	},
	{
		from: '/otmena-zapreta-na-vezd-v-rf-pomoshh-advokata/',
		to: '/articles/otmena-zapreta-na-vezd-v-rf-pomoshh-advokata',
	},
	{
		from: '/otmena-vydvoreniya-pomoshh-migracionnyx-yuristov/',
		to: '/articles/otmena-vydvoreniya-pomoshh-migracionnyx-yuristov',
	},
	{
		from: '/razreshenie-na-vremennoe-prozhivanie/',
		to: '/articles/razreshenie-na-vremennoe-prozhivanie',
	},
	{
		from: '/vid-na-zhitelstvo-v-rf/',
		to: '/articles/vid-na-zhitelstvo-v-rf',
	},
	{
		from: '/nuzhen-immigracionnyj-advokat/',
		to: '/articles/nuzhen-immigracionnyj-advokat',
	},
	{
		from: '/grazhdanstvo-rf-urozhencam-moldovy/',
		to: '/articles/grazhdanstvo-rf-urozhencam-moldovy',
	},
	{
		from: '/grazhdanstvo-po-ukazu-prezidenta-v-dejstvii/',
		to: '/articles/grazhdanstvo-po-ukazu-prezidenta-v-dejstvii',
	},
	{
		from: '/poteryali-dokumenty-i-annulirovali-pasport/',
		to: '/articles/poteryali-dokumenty-i-annulirovali-pasport',
	},
	{
		from: '/proverka-zapreta-na-vezd-v-rf/',
		to: '/articles/proverka-zapreta-na-vezd-v-rf',
	},
	{
		from: '/vnzh-po-stazhu-eto-prosto/',
		to: '/articles/vnzh-po-stazhu-eto-prosto',
	},
	{
		from: '/rvp-vnzh-i-grazhdanstvo-rossii-dlya-ukrainy-moldovy-i-kazaxstana/',
		to: '/articles/rvp-vnzh-i-grazhdanstvo-rossii-dlya-ukrainy-moldovy-i-kazaxstana',
	},
	{
		from: '/grazhdan-ukrainy-osvobodili-ot-uplaty-poshliny-i-sdachi-ekzamenov-po-russkomu-yazyku/',
		to: '/articles/grazhdan-ukrainy-osvobodili-ot-uplaty-poshliny-i-sdachi-ekzamenov-po-russkomu-yazyku',
	},
	{
		from: '/lyuboj-grazhdanin-ukrainy-mozhet-poluchit-grazhdanstvo-rossii/',
		to: '/articles/lyuboj-grazhdanin-ukrainy-mozhet-poluchit-grazhdanstvo-rossii',
	},
	{
		from: '/mobilnoe-prilozhenie-ot-yclients/',
		to: '/articles/mobilnoe-prilozhenie-ot-yclients',
	},
	{
		from: '/poluchit-grazhdanstvo-rf-mozhet-kazhdyj-grazhdanin-ukrainy/',
		to: '/articles/poluchit-grazhdanstvo-rf-mozhet-kazhdyj-grazhdanin-ukrainy',
	},
	{
		from: '/privet-mir/',
		to: '/articles/privet-mir',
	},
	{
		from: '/blizkie-rodstvenniki-inostrannyx-grazhdan-mogut-naxoditsya-v-rf-do-1-goda/',
		to: '/articles/blizkie-rodstvenniki-inostrannyx-grazhdan-mogut-naxoditsya-v-rf-do-1-goda',
	},
	{
		from: '/podacha-uvedomleniya-o-vtorom-grazhdanstve/',
		to: '/articles/podacha-uvedomleniya-o-vtorom-grazhdanstve',
	},
	{
		from: '/novyj-zakon-o-grazhdanstve-2023-kratkij-obzor/',
		to: '/articles/novyj-zakon-o-grazhdanstve-2023-kratkij-obzor',
	},
	{
		from: '/rvp-po-braku/',
		to: '/articles/rvp-po-braku',
	},
	{
		from: '/oformlenie-tranzitnoj-vizy/',
		to: '/articles/oformlenie-tranzitnoj-vizy',
	},
	{
		from: '/oformlenie-i-prodlenie-vizy-v-rf/',
		to: '/articles/oformlenie-i-prodlenie-vizy-v-rf',
	},
	{
		from: '/vid-na-zhitelstvo-v-sankt-peterburge/',
		to: '/articles/vid-na-zhitelstvo-v-sankt-peterburge',
	},
	{
		from: '/poluchenie-vida-na-zhitelstvo-v-moskve/',
		to: '/articles/poluchenie-vida-na-zhitelstvo-v-moskve',
	},
	{
		from: '/kvota-na-rvp-s-proverki-net/',
		to: '/articles/kvota-na-rvp-s-proverki-net',
	},
	{
		from: '/podtverzhdenie-rvp-i-vnzh-s-pomoshhyu-yuristov-proverki-net/',
		to: '/articles/podtverzhdenie-rvp-i-vnzh-s-pomoshhyu-yuristov-proverki-net',
	},
	{
		from: '/novyj-perechen-prioritetnyx-specialnostej-dlya-vnzh-i-grazhdanstva/',
		to: '/articles/novyj-perechen-prioritetnyx-specialnostej-dlya-vnzh-i-grazhdanstva',
	},
	{
		from: '/vy-podali-dokumenty-na-vnzh-chto-dalshe/',
		to: '/articles/vy-podali-dokumenty-na-vnzh-chto-dalshe',
	},
	{
		from: '/rvp/',
		to: '/articles/rvp',
	},
	{
		from: '/vy-podali-na-grazhdanstvo-chto-dalshe/',
		to: '/articles/vy-podali-na-grazhdanstvo-chto-dalshe',
	},
	{
		from: '/obyazatelnaya-daktiloskopiya-i-grin-karta/',
		to: '/articles/obyazatelnaya-daktiloskopiya-i-grin-karta',
	},
	{
		from: '/kvota-na-rvp-v-2023-godu/',
		to: '/articles/kvota-na-rvp-v-2023-godu',
	},
	{
		from: '/razreshenie-na-vremennoe-prozhivanie-v-celyax-polucheniya-obrazovaniya-rvpo/',
		to: '/articles/razreshenie-na-vremennoe-prozhivanie-v-celyax-polucheniya-obrazovaniya-rvpo',
	},
	{
		from: '/oformlenie-rvp-vnzh-i-grazhdanstva-v-advokatskom-byuro-proverki-net/',
		to: '/articles/oformlenie-rvp-vnzh-i-grazhdanstva-v-advokatskom-byuro-proverki-net',
	},
	{
		from: '/uslugi/konsultaciya-advokata/',
		to: '/uslugi/konsultaciya-advokata',
	},
	{
		from: '/uslugi/distancionnaya-konsultaciya/',
		to: '/uslugi/distancionnaya-konsultaciya',
	},
	{
		from: '/uslugi/vnzh-pod-klyuch/',
		to: '/uslugi/vnzh-pod-klyuch',
	},
	{
		from: '/uslugi/kvota-na-rvp/',
		to: '/uslugi/kvota-na-rvp',
	},
	{
		from: '/uslugi/rvp-pod-klyuch/',
		to: '/uslugi/rvp-pod-klyuch',
	},
	{
		from: '/uslugi/rvp-dlya-polucheniya-obrazovaniya/',
		to: '/uslugi/rvp-dlya-polucheniya-obrazovaniya',
	},
	{
		from: '/uslugi/grazhdanstvo-rf/',
		to: '/uslugi/grazhdanstvo-rf',
	},
	{
		from: '/uslugi/obzhalovaniye-annulirovaniya-dokumentov/',
		to: '/uslugi/obzhalovaniye-annulirovaniya-dokumentov',
	},
	{
		from: '/uslugi/zapret-na-vezd/',
		to: '/uslugi/zapret-na-vezd',
	},
	{
		from: '/uslugi/vydvoreniye/',
		to: '/uslugi/vydvoreniye',
	},
	{
		from: '/uslugi/legalizaciya-v-rf/',
		to: '/uslugi/legalizaciya-v-rf',
	},
	{
		from: '/uslugi/konsulskaya-legalizaciya/',
		to: '/uslugi/konsulskaya-legalizaciya',
	},
	{
		from: '/uslugi/apostil/',
		to: '/uslugi/apostil',
	},
];

export const urlRedirect = (url: string) => {
	const redirectUrl = redirectUrls.find(r => url === r.from);

	if (redirectUrl) {
		return redirectUrl.to;
	}

	return false;
};
