import { withoutLeadingSlash } from 'ufo';
import { Common } from '~/models/common';
import type { Resolver } from '~/models/resolver';

const collection = 'resolver';

export const resolverService = {
	fetch: async (): Promise<Resolver.Model[]> => {
		const { getItems } = useDirectusItems();

		return await getItems<Resolver.Model>({
			collection,
			params: {
				filter: {
					status: Common.Status.PUBLISHED,
				},
				fields: [
					'*',
					'form.*',
					'seo.label',
					'seo.title',
					'seo.header',
					'seo.keywords',
					'seo.description',
					'collectionItem.*',
				],
			},
		});
	},

	resolve: async (url: string): Promise<Resolver.Model | undefined> => {
		const { clearBreadcrumbs, addBreadcrumbs } = useBreadcrumbs(url);
		const { getItems } = useDirectusItems();

		clearBreadcrumbs();

		const resolvedPageUrlPart = url === '/' ? [''] : withoutLeadingSlash(url).split('/');
		const resolvedPageUrl = `/${resolvedPageUrlPart[0]}`;

		const [resolvedPage] = await getItems<Resolver.Model>({
			collection,
			params: {
				filter: {
					url: resolvedPageUrl,
					status: Common.Status.PUBLISHED,
					...(resolvedPageUrlPart.length > 1 ? { isSingleton: false } : {}),
				},
				fields: [
					'*',
					'form.*',
					'seo.label',
					'seo.title',
					'seo.header',
					'seo.keywords',
					'seo.description',
					'collectionItem.*',
				],
			},
		});

		if (!resolvedPage) return undefined;

		if (resolvedPageUrlPart.length === 1) {
			if (resolvedPage.seo) {
				addBreadcrumbs(resolvedPageUrl, resolvedPage.seo.label);
			}
			return (
				{
					...resolvedPage,
					isSubPage: false,
					alias: String(resolvedPageUrlPart[0]),
				} || undefined
			);
		} else if (resolvedPageUrlPart.length === 2) {
			if (resolvedPage.seo) {
				addBreadcrumbs(resolvedPage.url, resolvedPage.seo.label);
			}
			const [resolvedCollection] = await getItems<Resolver.Model>({
				collection: resolvedPage.collection,
				params: {
					filter: {
						alias: resolvedPageUrlPart[1],
						status: Common.Status.PUBLISHED,
					},
					fields: [
						'*',
						'form.*',
						'seo.label',
						'seo.title',
						'seo.header',
						'seo.keywords',
						'seo.description',
						'collectionItem.*',
					],
				},
			});

			if (!resolvedCollection) return undefined;

			const resolvedCollectionUrl = `/${resolvedPageUrlPart[0]}/${resolvedPageUrlPart[1]}`;
			if (resolvedCollection.seo) {
				addBreadcrumbs(resolvedCollectionUrl, resolvedCollection.seo.label);
			}

			return (
				{
					...resolvedPage,
					isSubPage: true,
					alias: String(resolvedPageUrlPart[1]),
					seo: resolvedCollection.seo,
				} || undefined
			);
		} else {
			return undefined;
		}
	},

	resolveByCollection: async (collectionName: string): Promise<Resolver.Model | undefined> => {
		const { getItems } = useDirectusItems();

		return (
			(
				await getItems<Resolver.Model>({
					collection,
					params: {
						filter: {
							collection: collectionName,
							status: Common.Status.PUBLISHED,
						},
						fields: [
							'*',
							'form.*',
							'seo.label',
							'seo.title',
							'seo.header',
							'seo.keywords',
							'seo.description',
						],
					},
				})
			)[0] || undefined
		);
	},
};
