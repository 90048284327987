import { Common } from '~/models/common';
import type { Menu } from '~/models/menu';

const collection = 'menu';

export const menuService = {
	fetch: async (): Promise<Menu.Model[]> => {
		const { getItems } = useDirectusItems();

		return await getItems<Menu.Model>({
			collection,
			params: {
				filter: {
					status: Common.Status.PUBLISHED,
				},
				fields: ['*', 'to.url'],
			},
		});
	},
};
