export namespace Modal {
	export const Type = {
		FEEDBACK: 'feedback',
		IFRAME: 'iframe',
	};

	export type Model = {
		type?: typeof Type.FEEDBACK | typeof Type.IFRAME;
		header: string;
		description: string;
		source: string;
		subject: string;
		form: ('name' | 'email' | 'phone')[];
		target: string | null;
		iframe?: string;
	};

	export const modelState: Model = {
		type: Type.FEEDBACK,
		header: 'Оставьте заявку',
		description: 'Заполните форму и мы свяжемся с вами в ближайшее время.',
		source: 'Неизвестный источник',
		subject: 'Обратная связь',
		form: ['name', 'phone'],
		target: null,
		iframe: undefined,
	};
}
