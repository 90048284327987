import { StatusCodes, ReasonPhrases } from 'http-status-codes';
import { resolverService } from '~/services/resolver';
import { Seo } from '~/models/seo';
import { Resolver } from '~/models/resolver';

export default defineNuxtRouteMiddleware(async (to, from) => {
	const router = useRouter();
	const { isServer } = useProcess();
	const { pageResolverName, pageResolverDir } = useAppConfig();
	const { notFoundComponent } = resolvedDefaultComponents();
	const { redirectPageType } = resolvedDefaultPageTypes();

	const isUrlRedirect = urlRedirect(to.path);

	if (isUrlRedirect !== false) {
		return navigateTo(isUrlRedirect, {
			replace: true,
			external: Boolean(urlRedirect(to.path)),
			redirectCode: StatusCodes.MOVED_PERMANENTLY,
		});
	}

	if (to.name === pageResolverName) {
		// RESOLVED PAGE
		const resolvePage = await resolverService.resolve(to.path);
		const resolvePageName = resolvedPageName(to.path);

		// REDIRECT TYPE
		if (resolvePage && resolvePage.pageType === redirectPageType && resolvePage.redirect) {
			return navigateTo(resolvePage.redirect, {
				replace: true,
				external: Boolean(resolvePage.redirect.startsWith('http')),
				redirectCode: StatusCodes.MOVED_PERMANENTLY,
			});
		}

		// RESOLVED COMPONENT
		const component = resolvedPageComponent(resolvePage?.pageType, pageResolverDir);

		// ADD ROUTE
		router.addRoute({
			path: to.path,
			name: resolvePageName,
			strict: true,
			component,
			meta: {
				seo: resolvePage?.seo || Seo.modelState,
				scrollToTop: () => true,
				pageType: resolvePage?.pageType,
				alias: resolvePage?.alias,
				isSubPage: resolvePage?.isSubPage,
				collectionItem:
					resolvePage?.collection === Resolver.Page.TEXT
						? resolvePage?.collectionItem
						: undefined,
			},
		});

		// NOT FOUND
		if (isServer && component.name === notFoundComponent) {
			throw createError({
				statusCode: StatusCodes.NOT_FOUND,
				statusMessage: ReasonPhrases.NOT_FOUND,
			});
		}

		return to.fullPath;
	}

	// NOT EXISTING ROUTE
	if (to.name && !router.hasRoute(to.name)) {
		return false;
	}

	// CLOSE POPUP's
	if (to.name && router.hasRoute(to.name)) {
		const { closeMenu } = useMenu();
		const { modalHide } = useModal();

		closeMenu();
		modalHide();
	}

	return true;
});
