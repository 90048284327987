import { Common } from '~/models/common';
import { Seo } from './seo';
import { Form } from './form';

export namespace Resolver {
	export const CommonComponents = {
		UNKNOWN: 'unknown',
		NOT_FOUNT: 'not-found',
	} as const;

	export const CommonPageTypes = {
		REDIRECT: 'redirect',
	} as const;

	export const Page = {
		MAIN: 'main',
		TEXT: 'text',
		ABOUT: 'about',
		SERVICES: 'services',
		EMPLOYEES: 'employees',
		ARTICLES: 'articles',
		ARBITRAGE: 'arbitrage',
		REVIEWS: 'reviews',
		CONTACTS: 'contacts',
	} as const;

	export type PageType = (typeof Resolver.Page)[keyof typeof Resolver.Page] | 'redirect';

	export type Collection = (typeof Resolver.Page)[keyof typeof Resolver.Page];

	export type collectionItem = {
		name: string;
		content: string;
	};

	export type CollectionWithoutItem = {
		collection: Exclude<PageType, typeof Page.TEXT>;
	};

	export type CollectionWithItem = {
		collection: typeof Page.TEXT;
		collectionItem: collectionItem;
	};

	export type Model = Common.Model &
		(CollectionWithoutItem | CollectionWithItem) & {
			url: string;
			pageType:
				| (typeof Resolver.Page)[keyof typeof Resolver.Page]
				| typeof Resolver.CommonPageTypes.REDIRECT;
			redirect?: string;
			alias: string;
			isSubPage: boolean;
			isSingleton: boolean;
			seo: Seo.Model | null;
			form: Form.Model | null;
		};
}
