import FloatingVue, { hideAllPoppers } from 'floating-vue';
import { Themes } from '~/models/floating-vue';

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.use(FloatingVue, {
		preventOverflow: false,
		themes: <Record<(typeof Themes)[keyof typeof Themes], unknown>>{
			base: {
				$extend: 'dropdown',
				noAutoFocus: true,
				distance: 10,
				disposeTimeout: 300,
				placement: 'auto',
			},
			tooltip: {
				$extend: 'base',
				triggers: ['click'],
				placement: 'bottom',
			},
			select: {
				distance: 2,
				triggers: ['click'],
				placement: 'bottom-start',
				autoHide: true,
			},
		},
	});

	addRouteMiddleware(
		'overlays-close',
		() => {
			if (import.meta.client) {
				hideAllPoppers();
			}
		},
		{ global: true }
	);
});
