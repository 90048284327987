export default defineNuxtPlugin(() => {
	// const { useHead } = useNuxtApp();

	useHead({
		script: [
			{
				src: 'https://w227836.yclients.com/widgetJS',
				type: 'text/javascript',
			},
		],
	});
});
