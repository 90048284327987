export namespace Seo {
	export type SeoLabel = string;
	export type SeoTitle = string;
	export type SeoHeader = string;
	export type SeoKeywords = string[];
	export type SeoDescription = string;

	export type Model = {
		label: SeoLabel | null;
		title: SeoTitle | null;
		header: SeoHeader | null;
		keywords: SeoKeywords | null;
		description: SeoDescription | null;
	};

	export const modelState: Model = {
		label: '',
		title: '',
		header: '',
		keywords: [''],
		description: '',
	};
}
