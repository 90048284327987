import { Modal } from '~/models/modal';

export const useModalState = () => ({
	modalState: useState<Modal.Model>('modalState', () => Modal.modelState),
	modalShown: useState<boolean>('modalShown', () => false),
});

export const useModal = () => {
	const { modalState, modalShown } = useModalState();

	/**
	 * Show modal
	 */
	const modalShow = () => {
		modalShown.value = true;
	};

	/**
	 * Hide modal
	 */
	const modalHide = () => {
		modalShown.value = false;
	};

	return {
		modalShown,
		modalState,

		modalShow,
		modalHide,
	};
};
