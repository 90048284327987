import { withoutLeadingSlash, withoutTrailingSlash } from 'ufo';
import { Resolver } from '~/models/resolver';

export const resolvedPageName = (path: string) =>
	withoutLeadingSlash(withoutTrailingSlash(path)).replaceAll('/', '__');

export const resolvedPageComponent = (
	pageType: typeof Resolver.CommonComponents.UNKNOWN | Resolver.PageType | undefined = undefined,
	pageResolverDir: string = 'pages-resolve'
) => {
	const map: Record<
		| (typeof Resolver.CommonComponents)[keyof typeof Resolver.CommonComponents]
		| Resolver.PageType,
		() => Promise<Component>
	> = {
		[Resolver.CommonComponents.UNKNOWN]: () =>
			import(`~/${pageResolverDir}/${Resolver.CommonComponents.UNKNOWN}.vue`).then(
				r => r.default || r
			),

		[Resolver.CommonComponents.NOT_FOUNT]: () =>
			import(`~/${pageResolverDir}/${Resolver.CommonComponents.NOT_FOUNT}.vue`).then(
				r => r.default || r
			),

		...Object.values(Resolver.Page).reduce(
			(acc, cur) => {
				acc[cur] = () =>
					import(`~/${pageResolverDir}/${cur}.vue`).then(r => r.default || r);
				return acc;
			},
			{} as Record<Resolver.PageType, () => Promise<Component>>
		),
	};

	return pageType
		? map[pageType] || map[Resolver.CommonComponents.UNKNOWN]
		: map[Resolver.CommonComponents.NOT_FOUNT];
};

export const resolvedDefaultComponents = () => {
	return {
		unknownComponent: Resolver.CommonComponents.UNKNOWN,
		notFoundComponent: Resolver.CommonComponents.NOT_FOUNT,
	};
};
export const resolvedDefaultPageTypes = () => {
	return {
		redirectPageType: Resolver.CommonPageTypes.REDIRECT,
	};
};
