import type { Menu } from '~/models/menu';
import { menuService } from '~/services/menu';

export const useMenuState = () => ({
	menuState: useState<Menu.Model[]>('menuState', () => []),
	menuLoaded: useState<boolean>('menuLoaded', () => false),
	menuPending: useState<boolean>('menuPending', () => true),
	menuToggle: useState<boolean>('menuToggle', () => false),
});

export const useMenu = () => {
	const { menuState, menuLoaded, menuPending, menuToggle } = useMenuState();

	/**
	 * Fetch menu
	 */
	const fetchMenu = async () => {
		if (menuLoaded.value) return;

		menuPending.value = true;

		menuState.value = await menuService.fetch();

		menuLoaded.value = true;
		menuPending.value = false;
	};

	/**
	 * Refresh menu
	 */
	const refreshMenu = async () => {
		menuLoaded.value = false;

		await fetchMenu();
	};

	/**
	 * Toggle menu
	 */
	const toggleMenu = () => {
		menuToggle.value = !menuToggle.value;
	};

	/**
	 * Close menu
	 */
	const closeMenu = () => {
		menuToggle.value = false;
	};

	return {
		menuState,
		menuPending,
		menuToggle,

		fetchMenu,
		refreshMenu,
		toggleMenu,
		closeMenu,
	};
};
