import type { Breadcrumbs } from '~/models/breadcrumbs';

export const useBreadcrumbsState = (url: string) => ({
	breadcrumbsState: useState<Breadcrumbs.Model[]>(`breadcrumbsState__${url}`, () => []),
});

export const useBreadcrumbs = (url: string) => {
	const { breadcrumbsState } = useBreadcrumbsState(url);

	/**
	 * Add breadcrumbs
	 */
	const addBreadcrumbs = (url: Breadcrumbs.Url, label: Breadcrumbs.Label) => {
		breadcrumbsState.value.push({ url, label: label || '——' });
	};

	/**
	 * Set breadcrumbs
	 */
	const setBreadcrumbs = (breadcrumbs: { url: Breadcrumbs.Url; label: Breadcrumbs.Label }[]) => {
		breadcrumbsState.value = breadcrumbs.reduce((acc, { url, label }) => {
			acc.push({ url, label: label || '——' });
			return acc;
		}, [] as Breadcrumbs.Model[]);
	};

	/**
	 * Clear breadcrumbs
	 */
	const clearBreadcrumbs = () => {
		breadcrumbsState.value = [];
	};

	return {
		breadcrumbsState,

		addBreadcrumbs,
		setBreadcrumbs,
		clearBreadcrumbs,
	};
};
