export namespace Common {
	export type Id = number;

	export type Sort = number | null;

	export const Status = {
		DRAFT: 'draft',
		PUBLISHED: 'published',
		ARCHIVED: 'archived',
	} as const;

	export type UserCreated = string | null;

	export type DateCreated = string | null;

	export type UserUpdated = string | null;

	export type DateUpdated = string | null;

	export type Model = {
		id: Id;
		sort: Sort;
		status: (typeof Status)[keyof typeof Status];
		user_created: UserCreated;
		date_created: DateCreated;
		user_updated: UserUpdated;
		date_updated: DateUpdated;
	};

	export type SingleModel = Omit<Common.Model, 'sort' | 'status'>;

	export type SelectOption = {
		label: string;
		value: Id;
		selected?: boolean;
	};

	export const modelState: Model = {
		id: 0,
		sort: null,
		status: Common.Status.PUBLISHED,
		user_created: null,
		date_created: null,
		user_updated: null,
		date_updated: null,
	};

	export const singleModelState: SingleModel = {
		id: 0,
		user_created: null,
		date_created: null,
		user_updated: null,
		date_updated: null,
	};
}
