import revive_payload_client_N6uohnsIAn from "/root/work/proverky-net/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@9.6.0_ioredis@5._tooqkfj3ltjfttz3si4dvpo2iu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hkCA4Yk8aX from "/root/work/proverky-net/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@9.6.0_ioredis@5._tooqkfj3ltjfttz3si4dvpo2iu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DnV5bcpLVP from "/root/work/proverky-net/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@9.6.0_ioredis@5._tooqkfj3ltjfttz3si4dvpo2iu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_9fdFDADX0D from "/root/work/proverky-net/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@9.6.0_ioredis@5._tooqkfj3ltjfttz3si4dvpo2iu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_NP6ImYZRvZ from "/root/work/proverky-net/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@9.6.0_ioredis@5._tooqkfj3ltjfttz3si4dvpo2iu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_5MOYkXjtON from "/root/work/proverky-net/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@9.6.0_ioredis@5._tooqkfj3ltjfttz3si4dvpo2iu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_fpE9QYeinT from "/root/work/proverky-net/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@9.6.0_ioredis@5._tooqkfj3ltjfttz3si4dvpo2iu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_DYVqdkEZ6k from "/root/work/proverky-net/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.0_typescript@5.5.3_vue@3.4.31_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/root/work/proverky-net/.nuxt/components.plugin.mjs";
import prefetch_client_bhPy9ZnoFD from "/root/work/proverky-net/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@9.6.0_ioredis@5._tooqkfj3ltjfttz3si4dvpo2iu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_PdP8CCTCM9 from "/root/work/proverky-net/node_modules/.pnpm/nuxt-directus@5.6.1_magicast@0.3.4_rollup@4.18.0/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import plugin_client_4VzB8LhfK1 from "/root/work/proverky-net/node_modules/.pnpm/nuxt-viewport@2.1.5_magicast@0.3.4_rollup@4.18.0_vue@3.4.31_typescript@5.5.3_/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import filters_VNgPjx6zHj from "/root/work/proverky-net/plugins/filters.ts";
import floating_vue_BiOD74u9sH from "/root/work/proverky-net/plugins/floating-vue.ts";
import maska_UHaKf2z1iQ from "/root/work/proverky-net/plugins/maska.ts";
import yandex_metrika_client_J47qiLqPYw from "/root/work/proverky-net/plugins/yandex-metrika.client.js";
import yclients_client_3w9POewKMR from "/root/work/proverky-net/plugins/yclients.client.js";
export default [
  revive_payload_client_N6uohnsIAn,
  unhead_hkCA4Yk8aX,
  router_DnV5bcpLVP,
  payload_client_9fdFDADX0D,
  navigation_repaint_client_NP6ImYZRvZ,
  check_outdated_build_client_5MOYkXjtON,
  chunk_reload_client_fpE9QYeinT,
  plugin_vue3_DYVqdkEZ6k,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bhPy9ZnoFD,
  plugin_PdP8CCTCM9,
  plugin_client_4VzB8LhfK1,
  filters_VNgPjx6zHj,
  floating_vue_BiOD74u9sH,
  maska_UHaKf2z1iQ,
  yandex_metrika_client_J47qiLqPYw,
  yclients_client_3w9POewKMR
]