import { default as _91_46_46_46pageResolver_93zURHqq3hbJMeta } from "/root/work/proverky-net/pages/[...pageResolver].vue?macro=true";
import { default as thanksjjmUz6hqxqMeta } from "/root/work/proverky-net/pages/thanks.vue?macro=true";
export default [
  {
    name: "pageResolver",
    path: "/:pageResolver(.*)*",
    component: () => import("/root/work/proverky-net/pages/[...pageResolver].vue").then(m => m.default || m)
  },
  {
    name: "thanks",
    path: "/thanks",
    component: () => import("/root/work/proverky-net/pages/thanks.vue").then(m => m.default || m)
  }
]